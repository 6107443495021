import React, { useEffect } from "react"
import GoogleMapReact from "google-map-react"
import { navigate } from "gatsby"
import MarkerClusterer from "@google/markerclusterer"
import { average } from "../../utils/math"
import styles from "./houses-map.module.scss"

function HousesGoogleMap({ houses, zoom }) {
  const filteredHouses = houses.filter(
    h => h.location && h.location.lat && h.location.lon
  )
  const lats = filteredHouses.map(h => h.location.lat)
  const lons = filteredHouses.map(h => h.location.lon)

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  const setGoogleMapRef = (map, maps) => {
    const icon = {
      path:
        "M20 7.093l-3-3v-2.093h3v5.093zm4 5.907h-3v10h-18v-10h-3l12-12 12 12zm-10 2h-4v6h4v-6z",
      fillColor: "#d93029",
      fillOpacity: 0.9,
      anchor: new maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1.3,
    }

    let markers = filteredHouses.map(h => {
      const title = `Будинок ${h.area} м2, ${h.city.name}`

      const marker = new maps.Marker({
        position: { lat: h.location.lat, lng: h.location.lon },
        icon: icon,
        title,
      })

      maps.event.addListener(marker, "click", () =>
        navigate(`/house/${h.slug}`)
      )

      return marker
    })

    new MarkerClusterer(map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      gridSize: 30,
      minimumClusterSize: 2,
    })
  }

  return (
    <div className={styles.housesMapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBvfR5GjYpWhniWGmG1pGmmGIcFj7MPS-Y",
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
        defaultCenter={{
          lat: average(lats) || 50.367004,
          lng: average(lons) || 30.465636,
        }}
        options={{ streetViewControl: true }}
        defaultZoom={zoom || 10}
      />
    </div>
  )
}

export default HousesGoogleMap
