import React from "react"

import GoogleMap from "../google/map/map"
import { average } from "../../utils/math"
import { PictureMapMarker } from "./marker"

import styles from "./infrastructures.module.scss"

const InfrastructuresMap = ({ places }) => {
  const filteredPlaces = places.filter(
    place => place.location && place.location.lat && place.location.lon
  )

  const defaultCenter = {
    lat: average(filteredPlaces.map(p => p.location.lat)) || 50.367004,
    lng: average(filteredPlaces.map(p => p.location.lon)) || 30.465636,
  }

  return (
    <div className={styles.infrastructuresMapContainer}>
      <GoogleMap defaultCenter={defaultCenter} defaultZoom={12}>
        {filteredPlaces.map(place => {
          return (
            <PictureMapMarker
              lat={place.location.lat}
              lng={place.location.lon}
              picture={place.photo}
              text={place.name}
              key={place.name}
            />
          )
        })}
      </GoogleMap>
    </div>
  )
}

export default InfrastructuresMap
