import React, { useRef, useState } from "react"
import Overlay from "react-overlays/Overlay"
import { getIcon } from "../../../utils"
import { Icon } from "../../../components/icon"
import "./picture-marker.scss"
import Img from "gatsby-image/index"

const PictureMapMarker = ({ text, picture }) => {
  const [show, setShow] = useState(false)
  const triggerRef = useRef(null)
  const containerRef = useRef(null)

  return (
    <div ref={containerRef}>
      <button
        type="button"
        className="map-picture-marker"
        id="overlay-toggle"
        ref={triggerRef}
        onClick={() => setShow(!show)}
      >
        <Icon name={text} icon={getIcon("location")} />
      </button>

      <Overlay
        show={show}
        rootClose
        placement="auto"
        flip
        offset={[0, 10]}
        onHide={() => setShow(false)}
        container={containerRef}
        target={triggerRef}
      >
        {({ props }) => (
          <div className="picture-marker-tooltip" {...props}>
            <div className="picture-marker-tooltip-body">
              <Img
                fluid={picture.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                className="picture-marker-tooltip-img"
                alt={text}
              />
              <p>{text}</p>
            </div>
          </div>
        )}
      </Overlay>
    </div>
  )
}

export default PictureMapMarker
